import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  QueryList,
  ViewChildren,
  AfterViewInit,
} from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faBadgeCheck,
  faCheckCircle,
  faTimesCircle,
} from '@fortawesome/pro-solid-svg-icons';
import {
  QualificationName,
  ShiftValidationDetails,
  ValidationCheckCategory,
} from '@wilson/interfaces';
import { TranslateModule } from '@ngx-translate/core';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { QualificationDetailsComponent } from '../qualification-details/qualification-details.component';
import { WorkingTimeDetailsComponent } from '../working-time-details/working-time-details.component';
import { ShiftLogicDetailsComponent } from '../shift-logic-details/shift-logic-details.component';

enum ValidationTabsEnum {
  QUALIFICATIONS,
  WORK_TIME_RULES,
  SHIFT_CONTENTS,
}

@Component({
  selector: 'wilson-timeline-shift-validation-tooltip',
  templateUrl: './timeline-shift-validation-tooltip.component.html',
  styleUrls: ['./timeline-shift-validation-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    FontAwesomeModule,
    TranslateModule,
    NzTabsModule,
    QualificationDetailsComponent,
    WorkingTimeDetailsComponent,
    ShiftLogicDetailsComponent,
  ],
})
export class TimelineShiftValidationTooltipComponent
  implements OnInit, AfterViewInit
{
  constructor(private cdRef: ChangeDetectorRef) {}

  protected ValidationCheckCategory = ValidationCheckCategory;
  protected faValidationTitleIcon = faBadgeCheck;
  protected faValidIcon = faCheckCircle;
  protected faInValidIcon = faTimesCircle;
  protected selectedCategory: ValidationCheckCategory | null = null;
  protected areQualificationsSufficient: boolean | undefined = true;
  protected areWorkingTimeRulesSufficient: boolean | undefined = true;
  protected areShiftLogicSufficient: boolean | undefined = true;
  protected areProfessionsSufficient: boolean | undefined = true;
  protected activeTab: ValidationTabsEnum = ValidationTabsEnum.QUALIFICATIONS;
  protected totalTabsWidth = 220;
  protected ValidationTabsEnum = ValidationTabsEnum;

  @Input() header = '';
  @Input() qualificationNames: QualificationName[] | null = [];
  @Input() professionValidations: ShiftValidationDetails[] | null = [];
  @Input() workingTimeValidations: ShiftValidationDetails[] | null | undefined =
    [];
  @Input() shiftLogicValidations: ShiftValidationDetails[] | null | undefined =
    [];
  @Input() noValidationFound = false;

  @ViewChildren('tabItem') tabItems: QueryList<ElementRef> =
    new QueryList<ElementRef>();

  ngOnInit(): void {
    this.areQualificationsSufficient = this.qualificationNames?.every(
      (validation) => validation.valid,
    );
    this.areWorkingTimeRulesSufficient = this.workingTimeValidations?.every(
      (validation: ShiftValidationDetails) => validation.valid,
    );

    this.areShiftLogicSufficient = this.shiftLogicValidations?.every(
      (validation) => validation.valid,
    );

    this.areProfessionsSufficient = this.professionValidations?.every(
      (validation) => validation.valid,
    );
  }

  ngAfterViewInit(): void {
    this.updateLineIndicator(ValidationTabsEnum.QUALIFICATIONS);
  }

  setSelectedCategory(category: ValidationCheckCategory): void {
    this.selectedCategory = category;
    this.cdRef.detectChanges();
  }

  onTabHover(activeTab: ValidationTabsEnum): void {
    this.activeTab = activeTab;
    this.updateLineIndicator(activeTab);
    this.showTabContent(activeTab);
  }

  onTabClick(activeTab: ValidationTabsEnum): void {
    this.activeTab = activeTab;
  }

  onTabsMouseLeave(): void {
    this.updateLineIndicator(this.activeTab);
    this.showTabContent(this.activeTab);
  }

  private updateLineIndicator(activeTab: ValidationTabsEnum): void {
    const tabWidth = this.totalTabsWidth / this.tabItems.length;
    const lineIndicator = document.querySelector(
      '.tab-line-indicator',
    ) as HTMLElement;
    if (lineIndicator) {
      lineIndicator.style.width = `${tabWidth}px`;
      lineIndicator.style.left = `${activeTab * tabWidth}px`;
    }
  }

  private showTabContent(activeTab: ValidationTabsEnum): void {
    const tabContents = document.querySelectorAll('.tab-content');
    tabContents.forEach((content) => content.classList.remove('active'));
    tabContents[activeTab].classList.add('active');
  }
}

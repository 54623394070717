<div class="tabs-container">
  <div class="tab-header" (mouseleave)="onTabsMouseLeave()">
    <div
      #tabItem
      class="tab-item"
      (mouseenter)="onTabHover(ValidationTabsEnum.QUALIFICATIONS)"
      (click)="onTabClick(ValidationTabsEnum.QUALIFICATIONS)"
    >
      <ng-container
        *ngTemplateOutlet="
          tabDetails;
          context: {
            isValid: !!(
              areQualificationsSufficient && areProfessionsSufficient
            ),
            title: ('page.shifts.qualifications_sufficient' | translate)
          }
        "
      ></ng-container>
    </div>
    <div
      #tabItem
      class="tab-item"
      (mouseenter)="onTabHover(ValidationTabsEnum.WORK_TIME_RULES)"
      (click)="onTabClick(ValidationTabsEnum.WORK_TIME_RULES)"
    >
      <ng-container
        *ngTemplateOutlet="
          tabDetails;
          context: {
            isValid: !!areWorkingTimeRulesSufficient,
            title: ('page.shifts.working_time_rules_observed' | translate)
          }
        "
      ></ng-container>
    </div>
    <div
      #tabItem
      class="tab-item"
      (mouseenter)="onTabHover(ValidationTabsEnum.SHIFT_CONTENTS)"
      (click)="onTabClick(ValidationTabsEnum.SHIFT_CONTENTS)"
    >
      <ng-container
        *ngTemplateOutlet="
          tabDetails;
          context: {
            isValid: !!areShiftLogicSufficient,
            title: ('page.shifts.content_ok' | translate)
          }
        "
      ></ng-container>
    </div>
    <div class="tab-line-indicator"></div>
  </div>

  <div
    class="tab-content"
    [class.active]="activeTab === ValidationTabsEnum.QUALIFICATIONS"
    id="tab-0"
  >
    <wilson-qualification-details
      [timelineView]="true"
      [qualificationNames]="qualificationNames ?? []"
      [professionValidations]="professionValidations ?? []"
    >
    </wilson-qualification-details>
  </div>

  <div
    class="tab-content"
    [class.active]="activeTab === ValidationTabsEnum.WORK_TIME_RULES"
    id="tab-1"
  >
    <wilson-working-time-details
      [timelineView]="true"
      [workingTimeValidations]="workingTimeValidations || []"
    >
    </wilson-working-time-details>
  </div>

  <div
    class="tab-content"
    [class.active]="activeTab === ValidationTabsEnum.SHIFT_CONTENTS"
    id="tab-2"
  >
    <wilson-shift-logic-details
      [timelineView]="true"
      [shiftLogicValidations]="shiftLogicValidations || []"
    >
    </wilson-shift-logic-details>
  </div>
</div>

<ng-template #tabDetails let-isValid="isValid" let-title="title">
  <fa-icon
    *ngIf="isValid"
    class="me-1 icon validIcon"
    [icon]="faValidIcon"
  ></fa-icon>
  <fa-icon
    *ngIf="!isValid"
    class="me-1 icon inValidIcon"
    [icon]="faInValidIcon"
  ></fa-icon>
  <span class="tab-text">{{ title }}</span>
</ng-template>
